jQuery(function () {
    if ($(window).width() > 1024.98) {
        $("#pagepiling").pagepiling({
            anchors: [
                "parkview",
                "gioi-thieu",
                "mat-bang-tong-the",
                "mat-bang-chi-tiet",
                "tien-ich",
                "shophouse",
                "nha-pho",
                "tin-tuc",
                "lien-he",
            ],
            menu: "#myMenu",
            navigation: false,
            afterRender: function () {},
        });
    }

    $(".menu-toggle").on("click", function () {
        $(".header-menu").toggleClass("active");
        $("header").toggleClass("active");
        $(this).find(".material-icons").toggleText("menu", "close");
        $(".logo").fadeToggle();
    });

    $(".search-toggle").on("click", function () {
        $(".header-search").toggleClass("active");
        $("header").toggleClass("active");
        $(this).find(".material-icons").toggleText("search", "close");
        $(".logo").fadeToggle();
    });

    imageMapJS();

    // $('.tooltip').trigger('mouseenter')
});

var homeBannerSlider = new Swiper(".home-banner .swiper-container", {
    loop: true,
    speed: 1300,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    autoplay: {
        delay: 4000
    }
});

var facilityBannerInfoSlider = new Swiper(
    ".facility-banner-info .swiper-container ",
    {
        slidesPerView: 3,
        spaceBetween: 5,
        observeParents: true,
        observer: true,
        slideToClickedSlide: true,
        breakpoints: {
            576: {
                slidesPerView: 2,
                centeredSlides: true,
            },
        },
    }
);

var facilityBannerImagesSlider = new Swiper(".facility-banner-images", {
    observeParents: true,
    observer: true,
    speed: 1300,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    navigation: {
        prevEl: ".facility-banner-images .swiper-btn-prev",
        nextEl: ".facility-banner-images .swiper-btn-next",
    },
    thumbs: {
        swiper: facilityBannerInfoSlider,
    },
});

var shopHouseSwiper = new Swiper(".shop-house-swiper", {
    slidesPerView: 1.75,
    centeredSlides: true,
    spaceBetween: 10,
    initialSlide: 1,
    navigation: {
        prevEl: ".shop-house-swiper .swiper-btn-prev",
        nextEl: ".shop-house-swiper .swiper-btn-next",
    },
    breakpoints: {
        1024.98: {
            slidesPerView: 1.15,
        },
    },
});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

function imageMapJS() {
    $("map").imageMapResize();

    $("#imageMap").maphilight({
        fillColor: "32b6ff",
        fillOpacity: 0.6,
        stroke: false,
        groupBy: "group",
    });

    $("#imageMap-2").maphilight({
        fillColor: "ed1c24",
        fillOpacity: 0.65,
        stroke: false,
        groupBy: "group",
    });

    $(".tooltip").tooltipster({
        contentCloning: true,
        minWidth: 240,
        onlyOne: true,
    });

    $(".tooltip-v2").tooltipster({
        contentCloning: true,
        minWidth: 100,
        onlyOne: true,
        position: "bottom",
    });
}
